@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap');

html {
  /* scroll-behavior: smooth; */
  overflow-x: hidden; /* react-reveal sometimes makes unwanted effect on some components */
};

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signature {
  font-family: Herr Von Muellerhoff, sans-serif;
}

/*width*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: -moz-linear-gradient(-45deg,#5CDB95 0%, #007033 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%,#5CDB95),
    color-stop(100%, #007033)
  );
  background: -webkit-linear-gradient(-45deg,#5CDB95 0%, #007033 100%);
  background: -o-linear-gradient(-45deg,#5CDB95 0%, #007033 100%);
  background: -ms-linear-gradient(-45deg,#5CDB95 0%, #007033 100%);
  background: linear-gradient(135deg,#5CDB95 0%, #007033 100%);

  border-radius: 10px;
}


  @media (min-width: 600px) {
    p, b, li {
    font-size: 18px;
    }
  }



